import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Title from "../../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../../base/components/FormikInput";
import Button from "../../../../../base/components/Button";
import { TagsDropdown } from "../../../../../base/components/Dropdowns/TagsDropdown/TagsDropdown";

import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { initialValues, validationSchema } from "./form";
import { ORDER_MIN } from "./constants";
import { CONTENTS_GROUP_LINKS } from "../../config";

import ContentCategoriesService from "../../../../../services/ContentCategoriesService";
import ToastService from "../../../../../services/ToastService";
import { map } from "lodash";


export function CategoryForm() {
  /**
   * @type {ContentCategoriesService}
   */
  const contentCategoriesService = useService(ContentCategoriesService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);
  const navigate = useNavigate();

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [category, setCategory] = useState(null);

  const { search: locationSearch } = useLocation();


  const {
    params: {
      editCategoryId
    }
  } = useQueryString(locationSearch);

  const afterSuccess = () => {
    toastService.success("Category has been successfully saved");
    navigate(CONTENTS_GROUP_LINKS.CATEGORIES_LIST);
    updateIsSubmitting(false);
  };

  const apiFunction = (category) => {
    if (editCategoryId) {
      return contentCategoriesService.updateContentCategory(editCategoryId, category);
    }

    return contentCategoriesService.createContentCategory(category);
  };

  const createCategory = async (values) => {
    updateIsSubmitting(true);

    apiFunction({ ...values, order: Number(values.order) })
      .then(afterSuccess)
      .finally(() => updateIsSubmitting(false));
  };

  const mapCategoryToForm = (category) => {
    return category;
  };

  const breadcrumbs = {
    title: editCategoryId ? "Edit Category" : "Create Category",
    breadcrumbItems: [
      { title: "Content", link: CONTENTS_GROUP_LINKS.CATEGORIES_LIST },
      { title: editCategoryId ? "Edit Category" : "Create Category" }
    ]
  };

  useEffect(() => {
    if (editCategoryId) {
      contentCategoriesService.deleteCategoryById(editCategoryId)
        .then((data) => {
          setCategory({ fullName: data.fullName, order: data.order, tagNames: map(data.categoriesTags, "tag.name") });
        });
    }
  }, [editCategoryId]);

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <Formik
      initialValues={category || initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={createCategory}
      enableReinitialize
    >
      {({ errors, handleSubmit, values, setFieldValue }) => {

        return <form
          className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
          onSubmit={handleSubmit}
        >
          <div className="w-50">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Title
                title="General information"
              />
            </div>
            <section>
              <FormikInput
                name="fullName"
                label="Full name"
                placeholder="Enter category name (required)"
              />

              <div className="mt-3">
                <TagsDropdown
                  value={values.tagNames}
                  onChange={(value) => setFieldValue("tagNames", value)}
                  placeholder="Enter tags (required)"
                  label="Tags"
                  error={errors.tagNames}
                />
              </div>

              <FormikInput
                type="number"
                placeholder="Please enter order"
                name="order"
                min={ORDER_MIN}
                label="Order"
                containerClassName="mt-3"
              />

              <div className="d-flex justify-content-end mt-5">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting}
                >
                  Save category
                </Button>
              </div>
            </section>
          </div>
        </form>;
      }}
    </Formik>
  </BaseLayoutWithCard>;
}