import React from "react";
import { map } from "lodash";

import ProgramsDropdown from "../../../../../base/components/Dropdowns/ProgramsDropdown/ProgramsDropdown";
import {
  UserSegmentsDropdown
} from "../../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";

import { isUnique } from "../../helpers";
import yup from "../../../../../validation/yup";
import {
  CONTENT_STATUS,
  MAX_SUBTITLE,
  MIN_STRING_LENGTH
} from "./constants";
import { MAX_TITLE, NAME_PATTERN } from "./constants";
import {
  CONTENT_TYPE_API_ID,
  PROGRAM_PLAN_CONTENT_TYPE,
  PROGRAM_PLAN_CONTENT_TYPE_LABELS
} from "../../../HealthProgram/constants";
import { CONTENT_ASSIGNED } from "../../constants";
import { AUTHOR_NAME_PATTERN, URL_PATTERN, VIMEO_VIDEO_PATTERN } from "../../../../../base/constants/patterns";
import { TAG_NAMES_MAX_ITEMS_LENGTH } from "../../constants";
import { ORDER_MIN } from "../CategoryForm/constants";

export const initialValues = {
  title: "",
  order: null,
  contentAssignedType: CONTENT_ASSIGNED.GENERAL,
  segments: [],
  programs: [],
  status: CONTENT_STATUS.ACTIVE,
  isFeatured: false,
  relatedContents: [null],
  tagNames: [],
  duration: { hours: '00', minutes: '00', seconds: '00' }

};


const videoUrlValidator = yup
  .string()
  .matches(VIMEO_VIDEO_PATTERN, 'Invalid URL')
  // .test('is-valid-video-url', 'Please check the video URL', async function (value) {
  //   if (!value) return true;
  //
  //   try {
  //     const response = await fetch(value);
  //     if (!response.ok) {
  //       return this.createError({ message: 'Please check the video URL' });
  //     }
  //     return true;
  //   } catch (error) {
  //     return this.createError({ message: 'Please check the video URL' });
  //   }
  // })
  .required();

const sourceUrlValidator = yup
  .string()
  .url('Invalid URL')
  .required();

const urlValidator = yup
  .string()
  .matches(
    URL_PATTERN,
    'Invalid url'
  )
  .required();

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_TITLE)
    .matches(NAME_PATTERN, "Invalid title")
    .required("Is required"),
  subtitle: yup
    .string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_SUBTITLE)
    .matches(NAME_PATTERN, "Invalid name")
    .required("Is required"),
  author: yup.object()
    .test('checkAuthorName', "Only letters, numbers, spaces, and hyphens are allowed", async function (value) {
      if (!value?.name) return true;
      return AUTHOR_NAME_PATTERN.test(value.name);
    })
    .required("Author name is required"),
  contentType: yup
    .string()
    .required("Is required"),
  segments: yup
    .array()
    .when('contentAssignedType', {
      is: CONTENT_ASSIGNED.SEGMENT,
      then: yup.array().min(1, "Must contain at least one segment").required(),
      otherwise: yup.array().nullable()
    }),
  programs: yup
    .array()
    .when('contentAssignedType', {
      is: CONTENT_ASSIGNED.PROGRAMS,
      then: yup.array().min(1, "Must contain at least one program").required(),
      otherwise: yup.array().nullable()
    }),
  imageURL: urlValidator
    .test('checkURL', 'URL is not reachable', async function (value) {
      if (!value) return true;

      try {
        const response = await fetch(value);
        if (!response.ok) {
          return this.createError({ message: 'URL is not reachable' });
        }
        return true;
      } catch (error) {
        return this.createError({ message: 'URL is not reachable' });
      }
    }),
  category: yup.mixed().required(),
  tagNames: yup.array()
    .test('max-tags', `Maximum ${TAG_NAMES_MAX_ITEMS_LENGTH} tags allowed.`, function (value) {
      const categoryTags = this.parent.category?.tags || [];
      const allTags = [...(value || []), ...categoryTags];
      return allTags.length <= TAG_NAMES_MAX_ITEMS_LENGTH;
    })
    .test('unique', 'Tags must be unique', function (value) {
      const categoryTags = this.parent.category?.tags || [];
      const allTags = [...(value || []), ...categoryTags];
      return isUnique(allTags);
    }),
  order: yup
    .number()
    .min(ORDER_MIN),
  videoURL: yup.string().when('contentType', {
    is: (contentType) => {
      return contentType === CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.VIDEO].toString();
    },
    then: videoUrlValidator,
    otherwise: yup.string().nullable()
  }),
  sourceURL: yup.string().when('contentType', {
    is: (contentType) => {
      return contentType === CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.VIDEO].toString();
    },
    then: yup.string().nullable(),
    otherwise: sourceUrlValidator
  }),
  relatedContents: yup
    .array()
    .test('unique', 'Contents must be unique', function (value) {
      const contentIds = map(value, 'id');
      return isUnique(contentIds);
    })
});

export const contentTypeOptions = Object.entries(CONTENT_TYPE_API_ID).map(([key, value]) => ({
  value,
  id: value,
  label: PROGRAM_PLAN_CONTENT_TYPE_LABELS[key]
}));

export const CONTENT_ASSIGNED_FIELDS = {
  [CONTENT_ASSIGNED.GENERAL]: null,
  [CONTENT_ASSIGNED.PROGRAMS]: ({ setFieldValue, value, error }) => {
    return <ProgramsDropdown
      onChange={(value) => setFieldValue(CONTENT_ASSIGNED_FIELD_NAMES[CONTENT_ASSIGNED.PROGRAMS], value)}
      value={value}
      label={"Programs"}
      placeholder={"Select programs (required)"}
      error={error}
    />;
  },
  [CONTENT_ASSIGNED.SEGMENT]: ({ setFieldValue, value, error }) => {
    return <UserSegmentsDropdown
      onChange={(value) => setFieldValue(CONTENT_ASSIGNED_FIELD_NAMES[CONTENT_ASSIGNED.SEGMENT], value)}
      value={value}
      label={"User Segment"}
      placeholder={"Select user segments (required)"}
      error={error}
    />;
  }
};

export const CONTENT_ASSIGNED_FIELD_NAMES = {
  [CONTENT_ASSIGNED.GENERAL]: null,
  [CONTENT_ASSIGNED.PROGRAMS]: 'programs',
  [CONTENT_ASSIGNED.SEGMENT]: 'segments'
};