import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";

import Checkbox from "./Checkbox";
import { useLoading } from "../hooks/useLoading";

const DEFAULT_FILTER_LIMIT = 15;

export const FilterListWithInfinityScroll = ({ filterState, updateFilterState, mapOptions, getData, label }) => {
  const [options, updateOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, { registerPromise }] = useLoading(true);
  const lastElementRef = useRef(null);

  const handleCheckboxChange = (id) => {
    updateFilterState(prevState => {
      return prevState.includes(id) ? prevState.filter(chosenId => chosenId !== id) : [...prevState, id];
    });
  };

  const loadData = () => {
    const params = { limit: DEFAULT_FILTER_LIMIT, offset: 0 };

    registerPromise(getData(params))
      .then((result => {
        updateOptions(mapOptions(result.data));
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
        setPage(1);
      }));
  };

  const loadMoreData = () => {
    if (isLoading || !hasNextPage) return;

    const nextOffset = (page) * DEFAULT_FILTER_LIMIT;

    const params = { limit: DEFAULT_FILTER_LIMIT, offset: nextOffset };

    registerPromise(getData(params))
      .then((result => {
        updateOptions([...options, ...mapOptions(result.data)]);
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
        setPage(page + 1);
      }));
  };

  useEffect(() => {
    if (!lastElementRef.current) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreData();
      }
    });

    observer.observe(lastElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreData, lastElementRef.current]);

  console.log(options);

  useEffect(() => {
    loadData();
  }, []);

  return <section className=" mx-1 me-4 ">
    <label className="text-secondary font-size-10 mb-1 font-weight-normal">{label}</label>
    <Checkbox
      text={"All"}
      value={!filterState.length}
      onChange={() => updateFilterState([])}
      className="my-2 ms-1"
    />

    <section className="custom-scrollbar filter-options rec-category-filter">
      <>
        {options.map((item, index) => {
          return (
            <Checkbox
              id={item.id}
              text={item.label}
              value={filterState?.includes(item.id)}
              onChange={() => {
                handleCheckboxChange(item.id, updateFilterState);
              }}
              key={index}
              className="my-2 ms-1"
            />
          );
        })}
        <div
          ref={lastElementRef}
          className="d-flex justify-content-center">
          {isLoading && <Spinner size="xs" color="primary"/>}
        </div>
      </>
    </section>


  </section>;
};