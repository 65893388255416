// Libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { map, difference } from "lodash";
import classnames from "classnames";

// Components, Views, Screens
import TableSearch from "../../../../base/components/Table/tableSearch";

// Hooks, Utils, Helpers
import { useService } from "../../../hooks/useService";
import { useLoading } from "../../../hooks/useLoading";
import MetaNutritionApi from "../../../../services/MetaNutritionApi";

// Styles, assets
import classes from "./RecipesDropdown.module.scss";
import { useHighlight } from "../../../hooks/useHighlight";
import { MAX_LIMIT } from "../../../constants/api";
import { RECIPE_STATUS } from "../../../constants/foods";

export const RecipesDropdown = ({ onChange, value = [], placeholder, label, error }) => {
    /**
     * @type {MetaNutritionApi}
     */
    const metaNutritionApi = useService(MetaNutritionApi);

    const [isOpen, updateIsOpen] = useState(false);
    const [recipes, setRecipe] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, { registerPromise }] = useLoading(true);
    const lastElementRef = useRef(null);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);

    const { decorateText } = useHighlight(search);

    const showPlaceholder = !value?.name;

    const mapRecipesToDropdown = (data = []) => {
        return data.map((item) => ({ id: item._id, name: item.foodName }));
    };

    const loadRecipe = useCallback(() => {
        registerPromise(metaNutritionApi.getRecipes({
            q: search,
            page: 1,
            limit: MAX_LIMIT,
            recipeValidationStatuses: RECIPE_STATUS.readyToUse,
            sortBy: "foodName"
        }))
            .then((result => {
                setRecipe(mapRecipesToDropdown(result.data));
                setPage(1);
                setHasNextPage(result.meta.pages > 1);
            }));
    }, [search]);

    const loadMoreRecipe = () => {
        if (isLoading || !hasNextPage) return;

        const nextPage = page + 1;

        registerPromise(metaNutritionApi.getRecipes({
            q: search,
            page: nextPage,
            limit: MAX_LIMIT,
            recipeValidationStatuses: RECIPE_STATUS.readyToUse,
            sortBy: "foodName"
        }))
            .then((result => {
                setRecipe((prev) => [...prev, ...mapRecipesToDropdown(result.data)]);
                setPage(nextPage);
                setHasNextPage(result.meta.pages > nextPage);
            }));
    };

    useEffect(() => {
        if (!lastElementRef.current) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreRecipe();
            }
        });

        observer.observe(lastElementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [loadMoreRecipe, lastElementRef.current]);

    useEffect(() => {
        loadRecipe();
    }, [loadRecipe]);

    return (<>
            {!!label && <label>{label}</label>}
                <section className="d-flex align-items-center w-100">
                    <Dropdown
                        isOpen={isOpen}
                        toggle={() => updateIsOpen(prevState => !prevState)}
                        className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
                        direction="down"
                    >
                        <DropdownToggle
                            className={classnames(
                                'filter-toggle w-100', {
                                    'with-border': isOpen,
                                    "is-invalid select-invalid": !!error
                                })}
                            tag="section"
                        >
                    <span
                        className={
                            classnames(
                                'ms-2 me-1 pointer-events-none user-select-none text-truncate',
                                { 'text-secondary': showPlaceholder }
                            )}
                    >
                        {
                            showPlaceholder
                                ? placeholder || 'Select recipe'
                                : value.name
                        }
                    </span>
                            <i className={
                                classnames(
                                    'mdi mdi-chevron-down pointer-events-none user-select-none',
                                    { 'mdi-rotate-180': isOpen }
                                )}
                            />
                        </DropdownToggle>

                        <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                            <TableSearch onSearch={setSearch} search={search} className="my-2"/>

                            <section>
                                <ListGroup>
                                    <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                        {recipes?.length
                                            ? recipes.map((item, index) => {
                                                return (
                                                    <>
                                                        <ListGroupItem
                                                            className="bg-transparent border-0"
                                                            key={item.id}
                                                            onClick={() => {
                                                                onChange(item);
                                                                updateIsOpen(false);
                                                            }}>
                                                            <div {...decorateText(item.name)} />
                                                        </ListGroupItem>
                                                        {
                                                            index === recipes.length - 1
                                                                ? <div
                                                                    ref={lastElementRef}
                                                                    className="d-flex justify-content-center pt-1">
                                                                </div>
                                                                : null
                                                        }
                                                    </>
                                                );
                                            })
                                            : <div className="text-secondary py-3 w-100 text-center">No result</div>
                                        }
                                    </div>
                                </ListGroup>
                                {isLoading && <div className="d-flex my-2 justify-content-center">
                                    <Spinner size="xs" color="primary"/>
                                </div>}
                            </section>
                        </DropdownMenu>
                    </Dropdown>
                </section>
        </>
    );
};

