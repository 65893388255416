import Http from "./Http";

class ContentCategoriesService extends Http {
    static $displayName = "ContentCategoriesService";

    async getContentCategories(params) {
        return await this.get(`/admins/content-categories`, { params });
    }

    deleteCategoryById(id) {
        return this.get(`/admins/content-categories/${id}`);
    }

    createContentCategory(values) {
        return this.post(`/admins/content-categories`, values);
    }

    updateContentCategory(id, values) {
        return this.put(`/admins/content-categories/${id}`, values);
    }

    deleteCategory(id) {
        return this.delete(`/admins/content-categories/${id}`);
    }
}

export default ContentCategoriesService;