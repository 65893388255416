import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { UncontrolledTooltip } from "reactstrap";
import { groupBy, map } from "lodash";


import Title from "../../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../../base/components/FormikInput";
import Button from "../../../../../base/components/Button";
import { AuthorDropdown } from "../../../../../base/components/Dropdowns/AuthorDropdown/AuthorDropdown";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import RadioButton from "../../../../../base/components/RadioButton";
import { TagsDropdown } from "../../../../../base/components/Dropdowns/TagsDropdown/TagsDropdown";
import { RelatedContentField } from "./components/RelatedContentField";
import TimeInput from "../../../../../base/components/TimeInput";
import Icon from "../../../../../base/components/Icon";
import Switch from "../../../../../base/components/Switch";
import { ContentCategoryDropdown } from "../../../../../base/components/Dropdowns/ContentCategoryDropdown/ContentCategoryDropdown";

import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { CONTENTS_GROUP_LINKS } from "../../config";
import { CONTENT_ASSIGNED, CONTENT_ASSIGNED_LABELS } from "../../constants";
import ContentsService from "../../../../../services/ContentsService";
import ToastService from "../../../../../services/ToastService";

import {
  CONTENT_ASSIGNED_FIELD_NAMES,
  CONTENT_ASSIGNED_FIELDS,
  contentTypeOptions,
  initialValues,
  validationSchema
} from "./form";
import { ORDER_MIN } from "../CategoryForm/constants";
import { CONTENT_STATUS } from "./constants";
import { CONTENT_TYPE_API_ID, PROGRAM_PLAN_CONTENT_TYPE, PROGRAM_TYPES } from "../../../HealthProgram/constants";
import { ENTER_KEY } from "../../../../../base/constants/system";

import imageFieldPlaceholder from "../../../../../assets/images/image-field-placeholder.png";

export function ContentForm() {
  /**
   * @type {ContentsService}
   */
  const contentsService = useService(ContentsService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);
  const navigate = useNavigate();

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [content, setContent] = useState(null);

  const { search: locationSearch } = useLocation();


  const {
    params: {
      editContentId
    }
  } = useQueryString(locationSearch);

  const afterSuccess = () => {
    toastService.success("Content has been successfully saved");
    navigate(CONTENTS_GROUP_LINKS.CONTENTS_LIST);
    updateIsSubmitting(false);
  };

  const apiFunction = (content) => {
    if (editContentId) {
      // return contentsService.updateContent(editContentId, content);
    }

    return contentsService.createContents(content);
  };

  const mapProgramFieldValueToPrograms = (programs) => {
    const groupedProgramsByType = groupBy(programs, "type");

    return Object.values(PROGRAM_TYPES).map((type) => {
      return {
        programType: type,
        programIds: map(groupedProgramsByType[type], "id")
      };
    });
  };

  const mapFormValuesToContent = ({
    author,
    category,
    order,
    relatedContents,
    tagNames,
    programs,
    segments,
    contentAssignedType,
    videoURL,
    duration,
    ...otherValues
  }) => {
    return {
      contentAssignedType,
      categoryId: category.id,
      authorName: author.name,
      order: Number(order),
      contentIds: map(relatedContents.filter(Boolean), "id"),
      tagNames: [...tagNames, ...category.tags],
      videoUrl: videoURL,
      duration: `${duration.hours || 0}h ${duration.minutes  || 0}m ${duration.seconds || 0}s`,
      programs: contentAssignedType === CONTENT_ASSIGNED.PROGRAMS ? mapProgramFieldValueToPrograms(programs) : undefined,
      segmentIds: contentAssignedType === CONTENT_ASSIGNED.SEGMENT ? map(segments, "id") : undefined,
      ...otherValues
    };
  };

  const createContent = async ({ ...otherValues }) => {
    updateIsSubmitting(true);

    apiFunction(mapFormValuesToContent({
      ...otherValues
    }))
      .then(afterSuccess)
      .finally(() => updateIsSubmitting(false));
  };

  const mapContentToForm = (content) => {
    return content;
  };

  const breadcrumbs = {
    title: editContentId ? "Edit Content" : "Create Content",
    breadcrumbItems: [
      { title: "Content", link: CONTENTS_GROUP_LINKS.CATEGORIES_LIST },
      { title: editContentId ? "Edit Content" : "Create Content" }
    ]
  };


  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <Formik
      initialValues={content || initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={createContent}
      enableReinitialize
    >
      {({ errors, handleSubmit, values, setFieldValue, validateField, setFieldTouched }) => {
        const [isCheckImage, setIsImageCheck] = useState(false);

        const ContentAssignedDropdownComponent = CONTENT_ASSIGNED_FIELDS[values.contentAssignedType];

        return <form
          className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === ENTER_KEY) {
              e.preventDefault();
            }
          }}

        >
          <div className="w-50">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Title
                title="General information"
              />
            </div>
            <section>
              <FormikInput
                name="title"
                label="Title"
                placeholder="Enter content title (required)"
              />

              <FormikInput
                name="subtitle"
                label="Subtitle"
                placeholder="Enter content subtitle (required)"
                containerClassName="mt-3"
              />

              <div className="mt-3">
                <AuthorDropdown
                  onChange={(value) => {
                    setFieldValue("author", value);
                  }}
                  value={values.author}
                  placeholder="Select author (required)"
                  label="Author"
                  error={errors.author}
                />
              </div>

              <FormikReactSelect
                name="contentType"
                options={contentTypeOptions}
                label="Content type"
                setFieldValue={setFieldValue}
                containerClassName="mt-3"
                placeholder="Select content type (required)"
                withError
              />

              <TimeInput
                placeholder="00h 00m 00s (optional)"
                name="duration"
                label="Duration (min)"
                containerClassName="mt-3"
                value={values.duration}
                handleChange={(value) => setFieldValue('duration', value)}
              />

              <div className="mt-3">
                <label>Content assigned:</label>
                <div className="d-flex gap-3">
                  {Object.values(CONTENT_ASSIGNED).map((contentAssignedType) => <RadioButton
                    key={contentAssignedType}
                    label={CONTENT_ASSIGNED_LABELS[contentAssignedType]}
                    onChange={() => {
                      setFieldValue("segments", []);
                      setFieldValue("programs", []);
                      setFieldValue("contentAssignedType", contentAssignedType);
                    }}
                    checked={values.contentAssignedType === contentAssignedType}
                    name="contentAssignedType"
                  />)}
                </div>
              </div>

              <div className="mt-3">
                {ContentAssignedDropdownComponent &&
                  <ContentAssignedDropdownComponent
                    setFieldValue={setFieldValue}
                    value={values[CONTENT_ASSIGNED_FIELD_NAMES[values.contentAssignedType]]}
                    containerClassName="mt-3"
                    error={errors?.[CONTENT_ASSIGNED_FIELD_NAMES[values.contentAssignedType]]}
                  />
                }
              </div>

              <div className="mt-3">
                <label>Image URL</label>

                <div className="d-flex align-items-start gap-2">
                  <FormikInput
                    name="imageURL"
                    placeholder="Enter image URL (required)"
                    containerClassName="w-100"
                    afterOnChange={async () => {
                      setIsImageCheck(true);
                      await validateField("imageURL");
                      await setFieldTouched("imageURL", true, true);
                      setIsImageCheck(false);
                    }}
                  />

                  <div className="field-image-preview">
                    <img
                      src={
                        !errors.imageURL && values.imageURL && !isCheckImage
                          ? values.imageURL
                          : imageFieldPlaceholder
                      }
                      alt="image"
                      onError={() => setIsImageCheck(true)}
                    />
                  </div>
                </div>
              </div>

              {
                values.contentType === CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.VIDEO]
                  ? <div className="mt-3">
                    <label>
                      Video URL
                      <Icon icon="infoCircle" className="ms-2" id={`video-info`}/>
                      <UncontrolledTooltip
                        popperClassName={joinClassNames(
                          "tooltip-alternative-name error-result-tooltip"
                        )}
                        innerClassName="pre-line text-truncate error-inner-max-height text-start"
                        placement="bottom"
                        target={`video-info`}
                      >
                        You can attach only Vimeo URL. Please make sure<br/>
                        that video has public access and permission to be downloaded.
                      </UncontrolledTooltip>
                    </label>
                    <FormikInput
                      name="videoURL"
                      placeholder="Enter video URL (required)"
                    />
                  </div>
                  : <FormikInput
                    name="sourceURL"
                    label="Source URL"
                    placeholder="Enter source URL (required)"
                    containerClassName="mt-3"
                  />
              }

              <div className="mt-3">
                <ContentCategoryDropdown
                  onChange={(value) => {
                    const filteredTags = values.category?.tags?.length
                      ? values.tagNames?.filter(tag => !values.category.tags.includes(tag))
                      : values.tagNames;

                    setFieldValue("tagNames", filteredTags);
                    setFieldValue("category", value);
                  }}
                  value={values.category}
                  placeholder="Select category (required)"
                  label="Category"
                  error={errors.category}
                />
              </div>

              <div className="mt-3">
                <TagsDropdown
                  value={values.tagNames}
                  onChange={(value) => setFieldValue("tagNames", value)}
                  placeholder="Enter tags (required)"
                  label="Tags"
                  error={errors.tagNames}
                  notEditValue={values.category?.tags}
                />
              </div>

              <FormikInput
                type="number"
                placeholder="Please enter order"
                name="order"
                min={ORDER_MIN}
                label="Order"
                containerClassName="mt-3"
              />

              <div className="d-flex gap-3 mt-3">
                <label className="d-flex align-items-center gap-1">
                  Status
                  <Switch state={values.status === CONTENT_STATUS.ACTIVE}
                          updateState={() => setFieldValue('status', values.status === CONTENT_STATUS.ACTIVE
                            ? CONTENT_STATUS.INACTIVE
                            : CONTENT_STATUS.ACTIVE)
                          }
                  />
                </label>

                <label className="d-flex align-items-center gap-1">
                  Featured
                  <Switch
                    state={values.isFeatured}
                    updateState={() => setFieldValue('isFeatured', !values.isFeatured)}
                  />
                </label>
              </div>

              <Title
                title="Learn more"
                className="mt-5"
              />

              <RelatedContentField/>

              <div className="d-flex justify-content-end mt-5">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting}
                >
                  Save content
                </Button>
              </div>
            </section>
          </div>
        </form>;
      }}
    </Formik>
  </BaseLayoutWithCard>;
}