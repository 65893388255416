import Http from "./Http";

class ContentsService extends Http {
    static $displayName = "ContentsService";

    async getContents(params) {
        return await this.get(`/admins/contents`, { params });
    }

    async createContents(values) {
        return await this.post(`/admins/contents`, values);
    }

    getContentAuthor(params) {
        return this.get(`/admins/content-authors`, { params })
    }
}

export default ContentsService;
